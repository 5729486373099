<template>
  <div class="col school_teachers_page_wrapper">
    <!-- Start Student Data  -->
    <div class="every_table_action_button">
      <button class="our_btn" @click="openAddDialog">
        <el-icon class="el-icon-plus"></el-icon>

        {{ $t("admin_dashboard_type.add_student") }}
      </button>
    </div>

    <server-table
      :count="schoolStudentsCount"
      :DataItems="schoolStudents"
      :enable_export="false"
      :useFilter="false"
      :action="false"
      @Refresh="Refresh"
      v-loading="loadingValue"
    >
      <template #columns>
        <el-table-column
          prop="name"
          :label="$t('admin_dashboard_type.student_name')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          :label="$t('admin_dashboard_type.email')"
        ></el-table-column>
        <el-table-column prop="city" :label="$t('admin_dashboard_type.city')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.city ? scope.row.city : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="class"
          :label="$t('admin_dashboard_type.class_name')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.class ? scope.row.class : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="grade" :label="$t('admin_dashboard_type.grade')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.grade ? `Grade ${scope.row.grade}` : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="_">
          <template slot-scope="scope">
            <div class="icons_table_wrapper">
              <i
                @click="removeStudent(scope.row.id)"
                class="fa fa-trash shadow-sm icon remove"
                v-tooltip.top-center="$t('admin_dashboard_type.delete_student')"
              ></i>
              <i
                class="fa fa-edit shadow-sm icon edit"
                @click="openEditDialog(scope.row)"
                v-tooltip.top-center="$t('admin_dashboard_type.edit_student')"
              ></i>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
    <!-- Start Student Data  -->

    <!-- Start Add Student  -->
    <el-dialog
      :visible.sync="showAddStudentDialog"
      :title="
        isEdit == false
          ? $t('admin_dashboard_type.add_student')
          : $t('admin_dashboard_type.edit_student')
      "
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- Switch Tabs  -->
      <div class="tabs_heading mb-3">
        <button
          :class="{ is_active: activeTabStudent == 1 }"
          class="our_buttons"
          @click="whichTabIsActive(1)"
        >
          {{
            isEdit == false
              ? $t("admin_dashboard_type.add_new_student")
              : $t("admin_dashboard_type.edit_student")
          }}
        </button>
        <button
          :class="{ is_active: activeTabStudent == 2 }"
          class="our_buttons"
          @click="whichTabIsActive(2)"
          v-if="isEdit == false"
        >
          {{ $t("admin_dashboard_type.add_more_student") }}
        </button>
        <button
          :class="{ is_active: activeTabStudent == 3 }"
          class="our_buttons"
          @click="whichTabIsActive(3)"
          v-if="isEdit == true"
        >
          {{ $t("admin_dashboard_type.change_password") }}
        </button>
        <button
          :class="{ is_active: activeTabStudent == 4 }"
          class="our_buttons"
          @click="whichTabIsActive(4)"
          v-if="isEdit == false"
        >
          {{ $t("admin_dashboard_type.download_student_template") }}
        </button>
      </div>
      <!-- Start Add One Teacher  -->
      <div v-if="activeTabStudent == 1">
        <el-form
          :model="studentData"
          :rules="isEdit == false ? addStudentRules : addStudentRulesWhenEdit"
          ref="addStudentForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.student_name')"
                prop="name"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.student_name')"
                  v-model="studentData.name"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.email')"
                prop="email"
                :rules="[
                  {
                    required: true,
                    type: 'email',
                    message: `${this.$t('correct_email_required')}`,
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.email')"
                  v-model="studentData.email"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6" v-if="isEdit == false">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.password')"
                  v-model="studentData.password"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.age')"
                prop="age_group"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.age')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="studentData.age_group"
                  :multiple="false"
                  :options="age_group"
                  label="title"
                  track-by="id"
                ></multiselect>
              </el-form-item>
            </div>
            <!-- <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.level')"
                prop="grade"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.level')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="studentData.grade"
                  :multiple="false"
                  :options="grades"
                  label="title"
                  track-by="id"
                ></multiselect>
              </el-form-item>
            </div> -->
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.gender')"
                prop="gender"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio-group v-model="studentData.gender">
                  <el-radio :label="1" @click="studentData.gender = 1">{{
                    $t("admin_dashboard_type.male")
                  }}</el-radio>
                  <el-radio :label="2" @click="studentData.gender = 2">
                    {{ $t("admin_dashboard_type.female") }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button
            @click="handlerFunction"
            class="when_submit_form save_from"
          >
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add One Student  -->
      <!-- Start Add More Students  -->
      <div v-if="activeTabStudent == 2 && isEdit == false">
        <div class="upload_excel_wrapper">
          <el-upload
            class="el-upload_btn"
            :show-file-list="false"
            accept=".xlsx"
            :on-success="successImport"
            :action="import_src"
            :on-error="handleErrorUpload"
            :headers="{
              Authorization: 'Bearer ' + testTst,
            }"
          >
            <el-icon class="el-icon-upload"></el-icon>
            {{ $t("admin_dashboard_type.add_excel_file") }}
          </el-upload>
        </div>

        <div class="creative_app_footer_dialog">
          <el-button class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="closeAddDialog"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add More Students  -->
      <!-- Start Change Student password  -->
      <div v-if="activeTabStudent == 3">
        <el-form
          :model="passData"
          ref="editedPasswordRef"
          :rules="editedPasswordRules"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="passData.password"
                  style="width: 100%"
                  :placeholder="$t('admin_dashboard_type.password')"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button @click="savePassword" class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- Start Change Student password  -->
      <!-- Start Export Template  -->
      <div v-if="activeTabStudent == 4 && isEdit == false">
        <div class="download_template_wrapper">
          <el-button @click="exportTemplate" class="download_template">
            {{ $t("admin_dashboard_type.download") }}</el-button
          >
        </div>
        <div class="creative_app_footer_dialog">
          <el-button class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="closeAddDialog"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Export Template -->
    </el-dialog>
    <!-- End Add Student -->
  </div>
</template>

<script>
import { getToken } from "../../utils/auth";
import ServerTable from "@/components/DataTablePagination.vue";
import Swal from "sweetalert2";
export default {
  components: { ServerTable },
  data() {
    return {
      activeTabStudent: 1,
      showAddStudentDialog: false,
      studentData: {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        gender: null,
        grade: null,
        age_group: null,
      },
      addStudentRules: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: `${this.$t("correct_email_required")}`,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        age_group: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      addStudentRulesWhenEdit: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: `${this.$t("correct_email_required")}`,
            trigger: "blur",
          },
        ],

        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        age_group: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      age_group: [
        { id: 0, title: this.$t("less_than_6") },
        { id: 1, title: 6 },
        { id: 2, title: 7 },
        { id: 3, title: 8 },
        { id: 4, title: 9 },
        { id: 5, title: 10 },
        { id: 6, title: 11 },
        { id: 7, title: 12 },
        { id: 8, title: this.$t("more_than_12") },
      ],
      grades: [
        {
          id: 1,
          title: "Grade 1",
        },
        {
          id: 2,
          title: "Grade 2",
        },
        {
          id: 3,
          title: "Grade 3",
        },
        {
          id: 4,
          title: "Grade 4",
        },
        {
          id: 5,
          title: "Grade 5",
        },
        {
          id: 6,
          title: "Grade 6",
        },
        {
          id: 7,
          title: "Grade 7",
        },
        {
          id: 8,
          title: "Grade 8",
        },
        {
          id: 9,
          title: "Grade 9",
        },
        {
          id: 10,
          title: "Grade 10",
        },
        {
          id: 11,
          title: "Grade 11",
        },
        {
          id: 12,
          title: "Grade 12",
        },
      ],
      isEdit: false,
      passData: {
        password: null,
        confirm_password: null,
      },
      editedPasswordRules: {
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.Refresh();
  },
  computed: {
    testTst() {
      // let tttt = {
      //   Authorization: "Bearer " + getToken(),
      //   "Content-Type": "application/json",
      //   Accept: "application/json",
      // };
      return getToken();
    },
    loadingValue() {
      return this.$store.getters["schools/load"];
    },
    schoolStudents() {
      return this.$store.getters["schools/schoolStudents"];
    },
    schoolStudentsCount() {
      return this.$store.getters["schools/schoolStudentsCount"];
    },
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    handlerFunction() {
      return this.isEdit == false ? this.addStudent : this.editStudent;
    },
    handlerCloseFunction() {
      return this.isEdit == false ? this.closeAddDialog : this.closeEditDialog;
    },
    import_src() {
      return `${process.env.VUE_APP_BASE_URL}schools/users/import`;
    },
  },
  methods: {
    Refresh(query = { offset: this.offset, limit: this.limit }) {
      this.$store.dispatch("schools/getListOfSchoolStudents", { query: query });
    },
    whichSpecialization(pcValue, anyArray) {
      if (pcValue != null) {
        let specialize = anyArray.filter((item) => {
          if (item.id == pcValue) {
            return item;
          }
        });

        return specialize[0].title;
      } else {
        return "__";
      }
    },
    openEditDialog(item) {
      this.showAddStudentDialog = true;
      this.isEdit = true;
      this.studentData = item;
      if (item.grade != null) {
        this.studentData.grade = {
          id: item.grade,
          title: this.whichSpecialization(item.grade, this.grades),
        };
      }
      console.log(item.age_group);
      if (item.age_group != null) {
        this.studentData.age_group = {
          id: item.age_group,
          title: this.whichSpecialization(item.age_group, this.age_group),
        };
      }
    },
    closeAddDialog() {
      this.showAddStudentDialog = false;
      this.activeTabStudent = 1;
    },
    closeEditDialog() {
      let beforeUpdateTeacherData = {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        gender: "",
        grade: "",
        age_group: "",
      };

      this.Refresh();
      this.showAddStudentDialog = false;
      this.isEdit = false;
      this.activeTabStudent = 1;
      this.studentData = beforeUpdateTeacherData;
    },
    openAddDialog() {
      this.activeTabStudent = 1;
      let beforeUpdateTeacherData = {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        gender: "",
        grade: "",
        age_group: "",
      };
      this.studentData = beforeUpdateTeacherData;
      this.showAddStudentDialog = true;
    },
    whichTabIsActive(num) {
      this.activeTabStudent = num;
    },

    addStudent() {
      this.$refs["addStudentForm"].validate((valid) => {
        if (valid) {
          this.studentData.confirm_password = this.studentData.password;
          this.studentData.grade = this.studentData.grade.id;
          this.studentData.age_group = this.studentData.age_group.id;
          this.$store
            .dispatch("schools/handlerAddStudent", this.studentData)
            .then(() => {
              this.showAddStudentDialog = false;
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_added")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
              this.studentData.name = null;
              this.studentData.email = null;
              this.studentData.password = null;
              this.studentData.confirm_password = null;
              this.studentData.gender = null;
              this.studentData.grade = null;
              this.studentData.age_group = null;
            });
        } else {
          return false;
        }
      });
    },
    removeStudent(id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("schools/deleteSchoolStudent", id).then((_) => {
            this.Refresh();
            Swal.fire({
              text: `${this.$t("success_deleted")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },

    editStudent() {
      this.$refs["addStudentForm"].validate((valid) => {
        if (valid) {
          let afterUpdateTeacherData = {
            name: null,
            email: null,
            password: null,
            confirm_password: null,
            gender: null,
            grade: null,
            age_group: null,
          };
          // this.showAddStudentDialog = false;
          // console.log(afterUpdateTeacherData);
          this.studentData.grade = this.studentData.grade.id;
          this.studentData.age_group = this.studentData.age_group.id;

          this.$store
            .dispatch("user/save", {
              query: this.studentData,
            })
            .then(() => {
              this.showAddStudentDialog = false;
              this.isEdit = false;
              this.studentData = afterUpdateTeacherData;
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_edited")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
            });
        }
      });
    },
    savePassword() {
      this.$refs["editedPasswordRef"].validate((valid) => {
        if (valid) {
          this.passData.confirm_password = this.passData.password;

          this.$store
            .dispatch("user/handler_update_student_password", {
              id: this.studentData.id,
              query: this.passData,
            })
            .then(() => {
              this.showAddStudentDialog = false;
              this.isEdit = false;
              this.activeTabStudent = 1;
              (this.passData.password = null),
                (this.passData.confirm_password = null),
                Swal.fire({
                  text: `${this.$t("success_edited")}`,
                  confirmButtonText: `${this.$t("confirm")}`,
                  icon: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false,
                });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    exportTemplate() {
      this.$store.dispatch("schools/handlerExportTemplate").then(() => {
        this.showAddStudentDialog = false;
        Swal.fire({
          text: `${this.$t("success_downloaded")}`,
          icon: "success",
          showCancelButton: false,
          confirmButtonClass: "btn btn-success btn-fill",
          confirmButtonText: `${this.$t("ok")}`,
          buttonsStyling: false,
        });
        this.activeTabStudent = 1;
      });
    },
    handleErrorUpload(error, file, fileList) {
      const errorMessage = JSON.parse(error.message)["error"];

      Swal.fire({
        text: `${errorMessage}`,
        type: "error",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
        icon: "error",
      });
      this.activeTabStudent = 1;
    },
    successImport() {
      this.showAddStudentDialog = false;
      this.Refresh();
      Swal.fire({
        text: `${this.$t("success_added")}`,
        icon: "success",
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false,
      });
      this.activeTabStudent = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.download_template_wrapper {
  display: flex;
  justify-content: center;
  .download_template {
    background-color: #87cb16;
    padding: 20px;
    border-radius: 9px;
    transition: 0.3s;
    font-size: 17px;
    min-width: 150px;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
}
::v-deep .cell {
  text-align: right;
  word-break: break-word;
}
.every_table_action_button {
  padding: 10px 0;
  .our_btn {
    background-color: #409eff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: 0.5;
    border: 1px solid #409eff;
    &:hover {
      opacity: 0.8;
    }
  }
}
.school_teachers_page_wrapper {
  .icons_table_wrapper {
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 5px 0;
    .icon {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      cursor: pointer;
      &.edit {
        background-color: #409eff;
        color: #fff;
      }
      &.remove {
        background-color: #fb404b;
        color: #fff;
      }
    }
  }
}
::v-deep .el-form--label-top .el-form-item__label {
  width: 100%;
  text-align: right;
}
::v-deep .el-form-item__error {
  right: 0;
  left: unset;
}
::v-deep .el-form-item {
  text-align: right;
}
.creative_app_footer_dialog {
  display: flex;
  margin-top: 15px;
  justify-content: end;
  padding: 10px;
  gap: 10px;
  .when_submit_form {
    min-width: 150px;
    text-align: center;
    transition: 0.3s;
    &.save_from {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
    }
    &.cancel_from {
      border: 1px solid #fb404b;
      background-color: #fb404b;
      color: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.tabs_heading {
  display: flex;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 3px solid #e4e4e4;
  .our_buttons {
    border: none;
    background: none;
    color: #777;
    position: relative;
    &::before {
      position: absolute;
      right: 0;
      content: "";
      background: #409eff;
      width: 100%;
      height: 3px;
      bottom: -12px;
      display: none;
    }
    &.is_active {
      &::before {
        display: block;
      }
    }
  }
}
.upload_excel_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
}
::v-deep .el-upload {
  background-color: #87cb16;
  padding: 20px;
  border-radius: 9px;
  transition: 0.3s;
  font-size: 17px;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
</style>
